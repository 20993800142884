import React from 'react';
import { BrowserView, MobileView } from 'react-device-detect';
import { portfolio } from '../Component/staticData'
import aboutUs from "../Image/About Us HWS.png";

function Portfolio() {

    function Project(props) {
        return (
            portfolio.map((project) => {
                return (
                <div className="flex row gap-24px">
                    <div className="flex col5">
                        <iframe src={project?.Img} alt="Project Image" height={'450px'} />
                    </div>
                    <div className="flex col5 py-60px">
                        <p>Project {project?.PortfolioId}</p>
                        <h2 className="HWS">{project?.Heading}</h2>
                        <ul className='aboutUL'>
                            {project?.Describtion.map((dataItem) => {
                                return (<li className='aboutLI' key={dataItem}>{dataItem.toString()}</li>);
                            })}
                        </ul>
                    </div>
                </div>
                )
            })
        )
    }

    return (
        <>
            <BrowserView>
                <section className="bg-section1 h-50 start-center">
                    <p className="tag mx-100px">Portfolio</p>
                    <h1 className="page-title">Our Work</h1>
                </section>
                <section className="mx-100px py-100px">
                    <Project />
                </section>
            </BrowserView>
            <MobileView>
                <section className="bg-section1 h-40 start-center">
                    <p className="tag">Portfolio</p>
                    <h1 className="page-title">Our Work</h1>
                </section>
                <section className="mx-20px py-30px">
                    <div className="flex col gap-16px">
                        <h2 className="heading">How we started:</h2>
                        <div className="flex">
                            <img className='aboutImg' src={aboutUs} alt="decoration" />
                        </div>
                        <div className="flex">
                            <ul className='aboutUL'>
                                <li className='aboutLI'>Techautm Solution is one of the most trusted web app development companies of India today. With a futuristic vision to grow in the tech industry, this company passionately in 2024 with a small team of employees providing ERP as primary service.</li>
                                <li className='aboutLI'>With their untiring energy and allegiance towards the work, in a couple of years, the company witnessed a roaring success and growth.</li>
                                <li className='aboutLI'>We have now grown to a workforce of over 80+ action-driven team members and offering a wide range of services such as Mobile Application Development (Android & iOS), Website Development (Custom, ECommerce, etc.), Custom Software Development, Desktop Application Development, CMS Development, ERP Solutions, FinTech Solutions, Blockchain Solutions and almost everything under the hood of computing and communication technology.</li>
                            </ul>
                        </div>
                    </div>
                </section>
            </MobileView>
        </>
    );
}

export default Portfolio;