import React from "react";
import { Link } from "react-router-dom";
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';

const Footer = () => {
    return (
        <>
            <BrowserView>
                <div className="bg-tx-black">
                    <div className="footer">
                        <div className="flex row justify-content-between">
                            <h6>Have a project in your <br />mind ? Let's talk</h6>
                            <div className="footer-icon flex row gap-16px align-items-center">
                                <div className="iconDiv">
                                    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M8.25004 20.1668H13.75C18.3334 20.1668 20.1667 18.3335 20.1667 13.7502V8.25016C20.1667 3.66683 18.3334 1.8335 13.75 1.8335H8.25004C3.66671 1.8335 1.83337 3.66683 1.83337 8.25016V13.7502C1.83337 18.3335 3.66671 20.1668 8.25004 20.1668Z" stroke="white" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M11 14.2082C11.4213 14.2082 11.8385 14.1252 12.2277 13.964C12.617 13.8027 12.9707 13.5664 13.2686 13.2685C13.5665 12.9706 13.8028 12.6169 13.9641 12.2276C14.1253 11.8384 14.2083 11.4212 14.2083 10.9998C14.2083 10.5785 14.1253 10.1613 13.9641 9.77206C13.8028 9.38281 13.5665 9.02912 13.2686 8.7312C12.9707 8.43328 12.617 8.19696 12.2277 8.03572C11.8385 7.87449 11.4213 7.7915 11 7.7915C10.1491 7.7915 9.333 8.12952 8.73133 8.7312C8.12965 9.33288 7.79163 10.1489 7.79163 10.9998C7.79163 11.8507 8.12965 12.6668 8.73133 13.2685C9.333 13.8702 10.1491 14.2082 11 14.2082Z" stroke="white" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M16.1664 6.4165H16.1774" stroke="white" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                </div>
                                <div className="iconDiv">
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1.91496 2.5L8.24305 10.9613L1.875 17.8406H3.30819L8.88341 11.8176L13.388 17.8406H18.2652L11.5811 8.90343L17.5084 2.5H16.0752L10.9407 8.04705L6.79217 2.5H1.91496ZM4.02258 3.55569H6.26318L16.1573 16.7848H13.9167L4.02258 3.55569Z" fill="white" />
                                    </svg>
                                </div>
                                <div className="iconDiv">
                                    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12.4609 5.74922V8.98906H15.3656C15.5891 8.98906 15.7008 9.2125 15.7008 9.43594L15.2539 11.5586C15.2539 11.6703 15.0305 11.782 14.9188 11.782H12.4609V19.9375H9.10938V11.8938H7.21016C6.98672 11.8938 6.875 11.782 6.875 11.5586V9.43594C6.875 9.2125 6.98672 9.10078 7.21016 9.10078H9.10938V5.41406C9.10938 3.51484 10.5617 2.0625 12.4609 2.0625H15.4773C15.7008 2.0625 15.8125 2.17422 15.8125 2.39766V5.07891C15.8125 5.30234 15.7008 5.41406 15.4773 5.41406H12.7961C12.5727 5.41406 12.4609 5.52578 12.4609 5.74922Z" stroke="white" strokeWidth="1.4" strokeMiterlimit="10" strokeLinecap="round" />
                                    </svg>
                                </div>
                            </div>
                        </div>
                        <p className="footer-text">Let's discuss your project requirement and grow together.</p>
                        <div className="flex row justify-content-between">
                            <div className="flex">
                                <div className="iconDiv me-16px">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M21.97 18.33C21.97 18.69 21.89 19.06 21.72 19.42C21.55 19.78 21.33 20.12 21.04 20.44C20.55 20.98 20.01 21.37 19.4 21.62C18.8 21.87 18.15 22 17.45 22C16.43 22 15.34 21.76 14.19 21.27C13.04 20.78 11.89 20.12 10.75 19.29C9.58811 18.4401 8.49169 17.5041 7.47 16.49C6.45877 15.472 5.5261 14.3789 4.68 13.22C3.86 12.08 3.2 10.94 2.72 9.81C2.24 8.67 2 7.58 2 6.54C2 5.86 2.12 5.21 2.36 4.61C2.6 4 2.98 3.44 3.51 2.94C4.15 2.31 4.85 2 5.59 2C5.87 2 6.15 2.06 6.4 2.18C6.66 2.3 6.89 2.48 7.07 2.74L9.39 6.01C9.57 6.26 9.7 6.49 9.79 6.71C9.88 6.92 9.93 7.13 9.93 7.32C9.93 7.56 9.86 7.8 9.72 8.03C9.59 8.26 9.4 8.5 9.16 8.74L8.4 9.53C8.29 9.64 8.24 9.77 8.24 9.93C8.24 10.01 8.25 10.08 8.27 10.16C8.3 10.24 8.33 10.3 8.35 10.36C8.53 10.69 8.84 11.12 9.28 11.64C9.73 12.16 10.21 12.69 10.73 13.22C11.27 13.75 11.79 14.24 12.32 14.69C12.84 15.13 13.27 15.43 13.61 15.61C13.66 15.63 13.72 15.66 13.79 15.69C13.87 15.72 13.95 15.73 14.04 15.73C14.21 15.73 14.34 15.67 14.45 15.56L15.21 14.81C15.46 14.56 15.7 14.37 15.93 14.25C16.16 14.11 16.39 14.04 16.64 14.04C16.83 14.04 17.03 14.08 17.25 14.17C17.47 14.26 17.7 14.39 17.95 14.56L21.26 16.91C21.52 17.09 21.7 17.3 21.81 17.55C21.91 17.8 21.97 18.05 21.97 18.33Z" stroke="white" strokeWidth="1.4" strokeMiterlimit="10" />
                                    </svg>
                                </div>
                                <p className="me-37px footer-contact">+91 91065 33086 | +91 96645 18737</p>
                                {/* <div className="iconDiv me-16px">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M11.9999 13.4299C12.4096 13.4299 12.8153 13.3492 13.1939 13.1924C13.5724 13.0357 13.9163 12.8058 14.2061 12.5161C14.4958 12.2264 14.7256 11.8824 14.8824 11.5039C15.0392 11.1254 15.1199 10.7197 15.1199 10.3099C15.1199 9.90022 15.0392 9.49451 14.8824 9.11597C14.7256 8.73743 14.4958 8.39349 14.2061 8.10377C13.9163 7.81405 13.5724 7.58423 13.1939 7.42744C12.8153 7.27064 12.4096 7.18994 11.9999 7.18994C11.1724 7.18994 10.3788 7.51865 9.79371 8.10377C9.2086 8.68888 8.87988 9.48247 8.87988 10.3099C8.87988 11.1374 9.2086 11.931 9.79371 12.5161C10.3788 13.1012 11.1724 13.4299 11.9999 13.4299Z" stroke="white" strokeWidth="1.4" />
                                        <path d="M3.61995 8.49C5.58995 -0.169998 18.42 -0.159997 20.38 8.5C21.53 13.58 18.37 17.88 15.6 20.54C14.632 21.4735 13.3397 21.9952 11.995 21.9952C10.6502 21.9952 9.35788 21.4735 8.38995 20.54C5.62995 17.88 2.46995 13.57 3.61995 8.49Z" stroke="white" strokeWidth="1.4" />
                                    </svg>
                                </div>
                                <p className="footer-contact">Surya Estate, Opp. Ram Mandir, Above Volkswagen Car</p> */}
                            </div>
                        </div>
                    </div>
                    <div className="copyrightTab">
                        © 2024 Techautm Solution
                    </div>
                </div>
            </BrowserView>
            <MobileView>
                <div className="bg-tx-black">
                    <div className="footer">
                        <div className="flex row justify-content-between">
                            <h6>Have a project in your <br />mind ? Let's talk</h6>
                        </div>
                        <p className="footer-text">Let's discuss your project requirement <br />and grow together.</p>
                        <div className="footer-icon flex row gap-16px align-items-center">
                            <div className="iconDiv">
                                <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M8.25004 20.1668H13.75C18.3334 20.1668 20.1667 18.3335 20.1667 13.7502V8.25016C20.1667 3.66683 18.3334 1.8335 13.75 1.8335H8.25004C3.66671 1.8335 1.83337 3.66683 1.83337 8.25016V13.7502C1.83337 18.3335 3.66671 20.1668 8.25004 20.1668Z" stroke="white" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M11 14.2082C11.4213 14.2082 11.8385 14.1252 12.2277 13.964C12.617 13.8027 12.9707 13.5664 13.2686 13.2685C13.5665 12.9706 13.8028 12.6169 13.9641 12.2276C14.1253 11.8384 14.2083 11.4212 14.2083 10.9998C14.2083 10.5785 14.1253 10.1613 13.9641 9.77206C13.8028 9.38281 13.5665 9.02912 13.2686 8.7312C12.9707 8.43328 12.617 8.19696 12.2277 8.03572C11.8385 7.87449 11.4213 7.7915 11 7.7915C10.1491 7.7915 9.333 8.12952 8.73133 8.7312C8.12965 9.33288 7.79163 10.1489 7.79163 10.9998C7.79163 11.8507 8.12965 12.6668 8.73133 13.2685C9.333 13.8702 10.1491 14.2082 11 14.2082Z" stroke="white" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M16.1664 6.4165H16.1774" stroke="white" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                            </div>
                            <div className="iconDiv">
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1.91496 2.5L8.24305 10.9613L1.875 17.8406H3.30819L8.88341 11.8176L13.388 17.8406H18.2652L11.5811 8.90343L17.5084 2.5H16.0752L10.9407 8.04705L6.79217 2.5H1.91496ZM4.02258 3.55569H6.26318L16.1573 16.7848H13.9167L4.02258 3.55569Z" fill="white" />
                                </svg>
                            </div>
                            <div className="iconDiv">
                                <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12.4609 5.74922V8.98906H15.3656C15.5891 8.98906 15.7008 9.2125 15.7008 9.43594L15.2539 11.5586C15.2539 11.6703 15.0305 11.782 14.9188 11.782H12.4609V19.9375H9.10938V11.8938H7.21016C6.98672 11.8938 6.875 11.782 6.875 11.5586V9.43594C6.875 9.2125 6.98672 9.10078 7.21016 9.10078H9.10938V5.41406C9.10938 3.51484 10.5617 2.0625 12.4609 2.0625H15.4773C15.7008 2.0625 15.8125 2.17422 15.8125 2.39766V5.07891C15.8125 5.30234 15.7008 5.41406 15.4773 5.41406H12.7961C12.5727 5.41406 12.4609 5.52578 12.4609 5.74922Z" stroke="white" strokeWidth="1.4" strokeMiterlimit="10" strokeLinecap="round" />
                                </svg>
                            </div>
                        </div>
                        <div className="flex col gap-16px">
                            <div className="flex gap-16px">
                                <div className="flex col iconDiv">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M21.97 18.33C21.97 18.69 21.89 19.06 21.72 19.42C21.55 19.78 21.33 20.12 21.04 20.44C20.55 20.98 20.01 21.37 19.4 21.62C18.8 21.87 18.15 22 17.45 22C16.43 22 15.34 21.76 14.19 21.27C13.04 20.78 11.89 20.12 10.75 19.29C9.58811 18.4401 8.49169 17.5041 7.47 16.49C6.45877 15.472 5.5261 14.3789 4.68 13.22C3.86 12.08 3.2 10.94 2.72 9.81C2.24 8.67 2 7.58 2 6.54C2 5.86 2.12 5.21 2.36 4.61C2.6 4 2.98 3.44 3.51 2.94C4.15 2.31 4.85 2 5.59 2C5.87 2 6.15 2.06 6.4 2.18C6.66 2.3 6.89 2.48 7.07 2.74L9.39 6.01C9.57 6.26 9.7 6.49 9.79 6.71C9.88 6.92 9.93 7.13 9.93 7.32C9.93 7.56 9.86 7.8 9.72 8.03C9.59 8.26 9.4 8.5 9.16 8.74L8.4 9.53C8.29 9.64 8.24 9.77 8.24 9.93C8.24 10.01 8.25 10.08 8.27 10.16C8.3 10.24 8.33 10.3 8.35 10.36C8.53 10.69 8.84 11.12 9.28 11.64C9.73 12.16 10.21 12.69 10.73 13.22C11.27 13.75 11.79 14.24 12.32 14.69C12.84 15.13 13.27 15.43 13.61 15.61C13.66 15.63 13.72 15.66 13.79 15.69C13.87 15.72 13.95 15.73 14.04 15.73C14.21 15.73 14.34 15.67 14.45 15.56L15.21 14.81C15.46 14.56 15.7 14.37 15.93 14.25C16.16 14.11 16.39 14.04 16.64 14.04C16.83 14.04 17.03 14.08 17.25 14.17C17.47 14.26 17.7 14.39 17.95 14.56L21.26 16.91C21.52 17.09 21.7 17.3 21.81 17.55C21.91 17.8 21.97 18.05 21.97 18.33Z" stroke="white" strokeWidth="1.4" strokeMiterlimit="10" />
                                    </svg>
                                </div>
                                <div className="flex col justify-content-center footer-contact">
                                    <p>+91 91065 33086 </p>
                                    <p>+91 96645 18737 </p>
                                </div>
                            </div>
                            {/* <div className="flex gap-16px">
                                <div className="flex col iconDiv">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M11.9999 13.4299C12.4096 13.4299 12.8153 13.3492 13.1939 13.1924C13.5724 13.0357 13.9163 12.8058 14.2061 12.5161C14.4958 12.2264 14.7256 11.8824 14.8824 11.5039C15.0392 11.1254 15.1199 10.7197 15.1199 10.3099C15.1199 9.90022 15.0392 9.49451 14.8824 9.11597C14.7256 8.73743 14.4958 8.39349 14.2061 8.10377C13.9163 7.81405 13.5724 7.58423 13.1939 7.42744C12.8153 7.27064 12.4096 7.18994 11.9999 7.18994C11.1724 7.18994 10.3788 7.51865 9.79371 8.10377C9.2086 8.68888 8.87988 9.48247 8.87988 10.3099C8.87988 11.1374 9.2086 11.931 9.79371 12.5161C10.3788 13.1012 11.1724 13.4299 11.9999 13.4299Z" stroke="white" strokeWidth="1.4" />
                                        <path d="M3.61995 8.49C5.58995 -0.169998 18.42 -0.159997 20.38 8.5C21.53 13.58 18.37 17.88 15.6 20.54C14.632 21.4735 13.3397 21.9952 11.995 21.9952C10.6502 21.9952 9.35788 21.4735 8.38995 20.54C5.62995 17.88 2.46995 13.57 3.61995 8.49Z" stroke="white" strokeWidth="1.4" />
                                    </svg>
                                </div>
                                <div className="flex col justify-content-center footer-contact">
                                    <p>Surya Estate, Opp. Ram Mandir, <br />Above Volkswagen Car</p>
                                </div>
                            </div> */}
                        </div>
                    </div>
                    <div className="copyrightTab">
                        © 2024 Techautm Solution
                    </div>
                </div>
            </MobileView>
        </>
    );
}

export default Footer;