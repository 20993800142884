import React, { useState } from 'react';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import { data } from '../Component/staticData'
import aboutUs from "../Image/About Us HWS.png";
import CEO from "../Image/Amita Shah.jpg";

function AboutUs() {
    const [sr, setSr] = useState(0);
    const [tImg, setTImg] = useState(CEO);
    const [testimonial, setTestimonial] = useState('It was a very good experience to work with TECHAUTM SOLUTION. They delivered the project before the estimated dead line with good quality and high commitment I will add them to my favorite freelancer list.  Thanks team for your support and great work');
    const [CEOName, setCEOName] = useState('Amita Shah');
    const [CEOPost, setCEOPost] = useState('Proprietor of Shetrapal Corporation');

    function changeTestimonial(e) {
        e.preventDefault();    //This is important, i'm not sure why, but the email won't send without it
        const button = e.target.id;
        if (button === 'previous') {
            setTImg(data.map((element) => {
                return element.img;
            })[sr - 1]);
            setTestimonial(data.map((element) => {
                return element.testimonial;
            })[sr - 1]);
            setCEOName(data.map((element) => {
                return element.ceoName;
            })[sr - 1]);
            setCEOPost(data.map((element) => {
                return element.ceoPost;
            })[sr - 1]);
            setSr(sr - 1);
        }
        if (button === 'next') {
            setTImg(data.map((element) => {
                return element.img;
            })[sr + 1]);
            setTestimonial(data.map((element) => {
                return element.testimonial;
            })[sr + 1]);
            setCEOName(data.map((element) => {
                return element.ceoName;
            })[sr + 1]);
            setCEOPost(data.map((element) => {
                return element.ceoPost;
            })[sr + 1]);
            setSr(sr + 1);
        }
    }

    return (
        <>
            <BrowserView>
                <section className="bg-section1 h-50 start-center">
                    <p className="tag mx-100px">About Us</p>
                    <h1 className="page-title">Who We Are</h1>
                </section>
                <section className="mx-100px py-100px">
                    <div className="flex row gap-24px">
                        <div className="flex col5">
                            <img src={aboutUs} alt="decoration" />
                        </div>
                        <div className="flex col5 py-60px">
                            <h2 className="HWS">How we started:</h2>
                            <ul className='aboutUL'>
                                <li className='aboutLI'>Techautm Solution  is one of the most trusted web app development companies of India today. With a futuristic vision to grow in the tech industry, this company passionately in 2024 with a small team of employees providing ERP as primary service.</li>
                                <li className='aboutLI'>With their untiring energy and allegiance towards the work, in a couple of years, the company witnessed a roaring success and growth.</li>
                                <li className='aboutLI'>We have now grown to a workforce of over 80+ action-driven team members and offering a wide range of services such as Mobile Application Development (Android & iOS), Website Development (Custom, ECommerce, etc.), Custom Software Development, Desktop Application Development, CMS Development, ERP Solutions, FinTech Solutions, Blockchain Solutions and almost everything under the hood of computing and communication technology.</li>
                            </ul>
                        </div>
                    </div>
                </section>
                <section className='bg-tx-black py-150px'>
                    <div className='mx-100px flex row gap-40px'>
                        <div className='flex col5'>
                            <div className='card-dark'>
                                <div className="card-icon">
                                    <svg className='mb-40px' width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="40" cy="40" r="39.3" strokeWidth="1.4" />
                                        <path d="M44.7732 40C44.7732 42.64 42.6399 44.7734 39.9999 44.7734C37.3599 44.7734 35.2266 42.64 35.2266 40C35.2266 37.36 37.3599 35.2267 39.9999 35.2267C42.6399 35.2267 44.7732 37.36 44.7732 40Z" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M39.9998 51.0267C44.7065 51.0267 49.0931 48.2534 52.1465 43.4534C53.3465 41.5734 53.3465 38.4134 52.1465 36.5334C49.0931 31.7334 44.7065 28.96 39.9998 28.96C35.2931 28.96 30.9065 31.7334 27.8531 36.5334C26.6531 38.4134 26.6531 41.5734 27.8531 43.4534C30.9065 48.2534 35.2931 51.0267 39.9998 51.0267Z" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                        <defs>
                                            <linearGradient id="paint0_linear_35_194" x1="0" y1="40" x2="80" y2="40" gradientUnits="userSpaceOnUse">
                                                <stop stopColor="#333399" />
                                                <stop offset="1" stopColor="#FF00CC" />
                                            </linearGradient>
                                            <linearGradient id="paint1_linear_35_194" x1="26.6667" y1="40" x2="53.3334" y2="40" gradientUnits="userSpaceOnUse">
                                                <stop stopColor="#333399" />
                                                <stop offset="1" stopColor="#FF00CC" />
                                            </linearGradient>
                                        </defs>
                                    </svg>
                                </div>
                                <h3 className='VMName'>Our Vision</h3>
                                <p className='VMDetails'>Make life easier by the digital transformation of your work through Techautm Solution.</p>
                            </div>
                        </div>
                        <div className='flex col5'>
                            <div className='card-dark'>
                                <div className="card-icon">
                                    <svg className='mb-40px' width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="40" cy="40" r="39.3" strokeWidth="1.4" />
                                        <path d="M40 50C42.6522 50 45.1957 48.9464 47.0711 47.0711C48.9464 45.1957 50 42.6522 50 40C50 37.3478 48.9464 34.8043 47.0711 32.9289C45.1957 31.0536 42.6522 30 40 30C37.3478 30 34.8043 31.0536 32.9289 32.9289C31.0536 34.8043 30 37.3478 30 40C30 42.6522 31.0536 45.1957 32.9289 47.0711C34.8043 48.9464 37.3478 50 40 50Z" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M39.9998 29.3334V26.6667M29.3332 40H26.6665M39.9998 50.6667V53.3334M50.6665 40H53.3332M39.9998 44C41.0607 44 42.0781 43.5786 42.8283 42.8284C43.5784 42.0783 43.9998 41.0609 43.9998 40C43.9998 38.9392 43.5784 37.9217 42.8283 37.1716C42.0781 36.4214 41.0607 36 39.9998 36C38.939 36 37.9216 36.4214 37.1714 37.1716C36.4213 37.9217 35.9998 38.9392 35.9998 40C35.9998 41.0609 36.4213 42.0783 37.1714 42.8284C37.9216 43.5786 38.939 44 39.9998 44Z" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                </div>
                                <h3 className='VMName'>Our Mission</h3>
                                <p className='VMDetails'>To provide high-quality services that will enhance business growth, while making long-term investors and employees proud.</p>
                            </div>
                        </div>
                    </div>
                </section>
                <section className='mx-100px py-150px'>
                    <div className='flex col mb-50px'>
                        <h5 className='testimonials'>Testimonials</h5>
                        <h4 className='heading testimonial-heading'>Don't take our words<br />trust our customer</h4>
                    </div>
                    <div className='slider'>
                        <div className='flex row justify-content-between'>
                            <div className='flex col'>
                                <img className='slider-img' src={tImg} alt='CEO' />
                            </div>
                            <div className='flex col7 justify-content-between'>
                                <div className='testimonial'>{testimonial}</div>
                                <div className='flex row justify-content-between align-items-center'>
                                    <div className='flex col'>
                                        <div className='CEOName'>{CEOName}</div>
                                        <div className='CEOPost'>{CEOPost}</div>
                                    </div>
                                    <div className='flex row gap-24px'>
                                        <svg id='previous' onClick={sr === 0 ? '' : changeTestimonial} opacity={sr === 0 ? '0.5' : '1'} width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ cursor: sr === 0 ? 'auto' : 'pointer' }}>
                                            <g>
                                                <path d="M15.9502 9.8833L5.8335 20L15.9502 30.1166M34.1668 20H6.11683" stroke="black" stroke-opacity="0.8" strokeWidth="1.4" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                            </g>
                                        </svg>
                                        <svg id='next' onClick={sr === data.length - 1 ? '' : changeTestimonial} opacity={sr === data.length - 1 ? '0.5' : '1'} width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ cursor: sr === data.length - 1 ? 'auto' : 'pointer' }}>
                                            <path d="M24.0498 9.8833L34.1665 20L24.0498 30.1166M5.83317 20H33.8832" stroke="black" stroke-opacity="0.8" strokeWidth="1.4" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </BrowserView>
            <MobileView>
                <section className="bg-section1 h-40 start-center">
                    <p className="tag">About Us</p>
                    <h1 className="page-title">Who We Are</h1>
                </section>
                <section className="mx-20px py-30px">
                    <div className="flex col gap-16px">
                        <h2 className="heading">How we started:</h2>
                        <div className="flex">
                            <img className='aboutImg' src={aboutUs} alt="decoration" />
                        </div>
                        <div className="flex">
                            <ul className='aboutUL'>
                                <li className='aboutLI'>Techautm Solution is one of the most trusted web app development companies of India today. With a futuristic vision to grow in the tech industry, this company passionately in 2024 with a small team of employees providing ERP as primary service.</li>
                                <li className='aboutLI'>With their untiring energy and allegiance towards the work, in a couple of years, the company witnessed a roaring success and growth.</li>
                                <li className='aboutLI'>We have now grown to a workforce of over 80+ action-driven team members and offering a wide range of services such as Mobile Application Development (Android & iOS), Website Development (Custom, ECommerce, etc.), Custom Software Development, Desktop Application Development, CMS Development, ERP Solutions, FinTech Solutions, Blockchain Solutions and almost everything under the hood of computing and communication technology.</li>
                            </ul>
                        </div>
                    </div>
                </section>
                <section className='bg-tx-black py-30px'>
                    <div className='mx-20px flex col gap-40px'>
                        <div className='flex col'>
                            <div className='card-dark'>
                                <div className="card-icon">
                                    <svg className='mb-20px' width="60" height="60" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="40" cy="40" r="39.3" strokeWidth="1.4" />
                                        <path d="M44.7732 40C44.7732 42.64 42.6399 44.7734 39.9999 44.7734C37.3599 44.7734 35.2266 42.64 35.2266 40C35.2266 37.36 37.3599 35.2267 39.9999 35.2267C42.6399 35.2267 44.7732 37.36 44.7732 40Z" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M39.9998 51.0267C44.7065 51.0267 49.0931 48.2534 52.1465 43.4534C53.3465 41.5734 53.3465 38.4134 52.1465 36.5334C49.0931 31.7334 44.7065 28.96 39.9998 28.96C35.2931 28.96 30.9065 31.7334 27.8531 36.5334C26.6531 38.4134 26.6531 41.5734 27.8531 43.4534C30.9065 48.2534 35.2931 51.0267 39.9998 51.0267Z" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                        <defs>
                                            <linearGradient id="paint0_linear_35_194" x1="0" y1="40" x2="80" y2="40" gradientUnits="userSpaceOnUse">
                                                <stop stopColor="#333399" />
                                                <stop offset="1" stopColor="#FF00CC" />
                                            </linearGradient>
                                            <linearGradient id="paint1_linear_35_194" x1="26.6667" y1="40" x2="53.3334" y2="40" gradientUnits="userSpaceOnUse">
                                                <stop stopColor="#333399" />
                                                <stop offset="1" stopColor="#FF00CC" />
                                            </linearGradient>
                                        </defs>
                                    </svg>
                                </div>
                                <h3 className='VMName'>Our Vision</h3>
                                <p className='VMDetails'>Make life easier by the digital transformation of your work through Techautm Solution.</p>
                            </div>
                        </div>
                        <div className='flex col'>
                            <div className='card-dark'>
                                <div className="card-icon">
                                    <svg className='mb-20px' width="60" height="60" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="40" cy="40" r="39.3" strokeWidth="1.4" />
                                        <path d="M40 50C42.6522 50 45.1957 48.9464 47.0711 47.0711C48.9464 45.1957 50 42.6522 50 40C50 37.3478 48.9464 34.8043 47.0711 32.9289C45.1957 31.0536 42.6522 30 40 30C37.3478 30 34.8043 31.0536 32.9289 32.9289C31.0536 34.8043 30 37.3478 30 40C30 42.6522 31.0536 45.1957 32.9289 47.0711C34.8043 48.9464 37.3478 50 40 50Z" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M39.9998 29.3334V26.6667M29.3332 40H26.6665M39.9998 50.6667V53.3334M50.6665 40H53.3332M39.9998 44C41.0607 44 42.0781 43.5786 42.8283 42.8284C43.5784 42.0783 43.9998 41.0609 43.9998 40C43.9998 38.9392 43.5784 37.9217 42.8283 37.1716C42.0781 36.4214 41.0607 36 39.9998 36C38.939 36 37.9216 36.4214 37.1714 37.1716C36.4213 37.9217 35.9998 38.9392 35.9998 40C35.9998 41.0609 36.4213 42.0783 37.1714 42.8284C37.9216 43.5786 38.939 44 39.9998 44Z" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                </div>
                                <h3 className='VMName'>Our Mission</h3>
                                <p className='VMDetails'>To provide high-quality services that will enhance business growth, while making long-term investors and employees proud.</p>
                            </div>
                        </div>
                    </div>
                </section>
                <section className='mx-20px py-30px'>
                    <div className='flex col mb-50px'>
                        <h5 className='testimonials'>Testimonials</h5>
                        <h4 className='heading testimonial-heading'>Don't take our words<br />trust our customer</h4>
                    </div>
                    <div className='slider'>
                        <div className='flex col justify-content-between'>
                            <div className='flex col'>
                                <img className='slider-img' src={tImg} alt='CEO' />
                            </div>
                            <div style={{ position: 'relative', marginTop: '10px' }}>
                                <div className='flex col7 justify-content-between'>
                                    <div className='testimonial'>{testimonial}</div>
                                    <div className='flex col'>
                                        <div className='CEOName'>{CEOName}</div>
                                        <div className='CEOPost'>{CEOPost}</div>
                                    </div>
                                </div>
                                <div className='flex row gap-24px' style={{ position: 'absolute', top: 0, right: 0 }}>
                                    <svg id='previous' onClick={sr === 0 ? '' : changeTestimonial} opacity={sr === 0 ? '0.5' : '1'} width="30" height="30" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ cursor: sr === 0 ? 'auto' : 'pointer' }}>
                                        <g>
                                            <path d="M15.9502 9.8833L5.8335 20L15.9502 30.1166M34.1668 20H6.11683" stroke="black" stroke-opacity="0.8" strokeWidth="1.4" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                        </g>
                                    </svg>
                                    <svg id='next' onClick={sr === data.length - 1 ? '' : changeTestimonial} opacity={sr === data.length - 1 ? '0.5' : '1'} width="30" height="30" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ cursor: sr === data.length - 1 ? 'auto' : 'pointer' }}>
                                        <path d="M24.0498 9.8833L34.1665 20L24.0498 30.1166M5.83317 20H33.8832" stroke="black" stroke-opacity="0.8" strokeWidth="1.4" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </MobileView>
        </>
    );
}

export default AboutUs;