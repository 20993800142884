import React from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import NoPage from "../Pages/NoPage";
import Layout from "../Pages/Layout";
import Home from "../Pages/Home";
import Career from "../Pages/Career";
import CareerDesc from "../Pages/CareerDesc";
import ContactUs from "../Pages/ContactUs";
import AboutUs from "../Pages/AboutUs";
import Portfolio from "../Pages/Portfolio";

const Router = () => {

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
              <Layout />
          }
        >
          <Route index element={<Home />} />
          <Route path="about-us" element={<AboutUs />} />
          <Route path="career" element={<Career />} />
          <Route path="career-detail/*" element={<CareerDesc />} />
          <Route path="our-work" element={<Portfolio />} />
          <Route path="contact-us" element={<ContactUs />} />
        </Route>
        <Route path="*" element={<NoPage />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
