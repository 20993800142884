import React, {useEffect, useState} from "react";
import { Link } from "react-router-dom";
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import logo from '../Image/logo-full.png';
import {menuIcon} from './staticData';

const Header = () => {
    const [navBg, setNavBg] = useState(false);
    const [path, setPath] = useState(window.location.pathname);
    const changeNav = () =>
    {
     window.scrollY >= 100 ? setNavBg(true) : setNavBg(false);
    }
    const toggleMenu = () => {
        document.getElementById('menu').classList.toggle('flex');
    };
    const changeActive = () => {
        setPath(window.location.pathname);
    };
    useEffect(() => {
        window.addEventListener('scroll', changeNav);
        return () => {
          window.removeEventListener('scroll', changeNav);
        }
    }, [])
    useEffect(() => {
        window.addEventListener('click', changeActive);
        return () => {
          window.removeEventListener('click', changeActive);
        }
    }, [])
    return (
        <>
            <BrowserView>
                <div className={`bg-transparent fixed w-100 ${navBg && 'bg-lightBlack py-10px'}`}>
                    <div className={`flex justify-content-between align-items-center ${navBg &&'mx-100px'}`}>
                    <img src={logo} alt="Techautm Solution" className={`logo ${navBg && 'logo-sm'}`} />
                    <div className='menu'>
                        <Link to='/' className={`menu-item ${path === '/' && 'active'}`} onClick={changeActive}>Home</Link>
                        <Link to='/about-us' className={`menu-item ${path === '/about-us' && 'active'}`} onClick={changeActive}>About Us</Link>
                        <Link to='/career' className={`menu-item ${(path === '/career' || path.toString().slice(0, 15) === '/career-detail/') && 'active'}`} onClick={changeActive}>Career</Link>
                        <Link to='/our-work' className={`menu-item ${path === '/our-work' && 'active'}`} onClick={changeActive}>Portfolio</Link>
                        <Link to='/contact-us' className={`menu-item ${path === '/contact-us' && 'active'}`} onClick={changeActive}>Contact Us</Link>
                    </div>
                    <div className='menu-button'>
                        <Link to='/' className='menu-item'>Sign In</Link>
                        <button className='menu-item'>Sign Up</button>
                    </div>
                    </div>
                </div>
            </BrowserView>
            <MobileView>
                <div className={`fixed py-20px w-100 ${navBg && 'bg-lightBlack'}`} style={{zIndex :1}}>
                    <div className={`flex justify-content-between align-items-center mx-20px`}>
                        <img src={logo} alt="Techautm Solution" className={`logo ${navBg && 'logo-sm'}`} />
                        <div onClick={toggleMenu}>{menuIcon}</div>
                        <div id="menu" className="">
                            <div id="closeBtn" onClick={toggleMenu}>X</div>
                            <div>
                                <Link to='/' className={`menu-item ${path === '/' && 'active'}`} onClick={() => {changeActive(); toggleMenu();}} >Home</Link>
                                <Link to='/about-us' className={`menu-item ${path === '/about-us' && 'active'}`} onClick={() => {changeActive(); toggleMenu();}} >About Us</Link>
                                <Link to='/career' className={`menu-item ${(path === '/career' || path.toString().slice(0, 15) === '/career-detail/') && 'active'}`} onClick={() => {changeActive(); toggleMenu();}} >Career</Link>
                                <Link to='/our-work' className={`menu-item ${path === '/our-work' && 'active'}`} onClick={() => {changeActive(); toggleMenu();}} >Portfolio</Link>
                                <Link to='/contact-us' className={`menu-item ${path === '/contact-us' && 'active'}`} onClick={() => {changeActive(); toggleMenu();}} >Contact Us</Link>
                            </div>
                            <div className='menu-button'>
                                <Link to='/' className='menu-item' onClick={toggleMenu}>Sign In</Link>
                                <button className='menu-item' onClick={toggleMenu}>Sign Up</button>
                            </div>
                        </div>
                    </div>
                </div>
            </MobileView>
        </>
    );
  }
  
  export default Header;