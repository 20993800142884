import React, { useState } from 'react';
import _ from "lodash";
import axios from 'axios';
import { BrowserView, MobileView } from 'react-device-detect';
import { jobDetails } from '../Component/staticData';

function CareerDesc() {
    const [error, setError] = useState({
        name: "",
        email: "",
        contact: "",
        file: ""
    });
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [file, setFile] = useState("");
    const [selectedFile, setSelectedFile] = useState(null);

    var Name = window.location.pathname;

    function isValidEmail(email) {
        // eslint-disable-next-line
        return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email);
    }

    function isValidContact(phone) {
        // eslint-disable-next-line
        return /^(\+91[\-\s]?)?[0]?(91)?[789]\d{9}$/.test(phone);
    }

    const showModal = () => {
        document.getElementById('root').style.height = '100vh';
        document.getElementById('root').style.overflow = 'hidden';
        document.getElementById('modal').style.display = 'flex';
    };

    const hideModal = () => {
        document.getElementById('root').style.height = '';
        document.getElementById('root').style.overflow = '';
        document.getElementById('modal').style.display = 'none';
    };

    const handleNameChange = (e) => {
        setName(e.target.value);
        setError({ name: "" });
    };
    const handlePhoneChange = (e) => {
        if (e?.nativeEvent?.data === '+' && phone === '') {
            setPhone(e.target.value);
            setError({ contact: "" });
        }
        else if (isNaN(e?.nativeEvent?.data)) {
            return;
        }
        else {
            setPhone(e.target.value);
            setError({ contact: "" });
        }
    };
    const handleEmailChange = (e) => {
        setEmail(e.target.value);
        setError({ email: "" });
    };
    const handleFileChange = (e) => {
        setFile(e.target.value);
        setSelectedFile(e.target.files[0]);
        setError({ file: "" });
    };

    function JobList(props) {
        return (
            jobDetails.map((jobDetail) => {
                var jobName = jobDetail.jobName;
                if (Name.toString().slice(15).replace("%20", " ") === jobName) {
                    if (props.head === "Responsibility" || props.head === "Skills") {
                        return (<ul key={props.head} className="CareerList">
                            {jobDetail[props.head].map((dataItem) => {
                                return (<li key={dataItem}>{dataItem.toString()}</li>);
                            })}
                        </ul>)
                    }
                    else return jobDetail[props.head]
                }
            })
        )
    }

    const handleSubmit = () => {
        if (_.isEmpty(name)) {
            setError({ name: "Please enter name here" });
        }
        else if (_.isEmpty(phone)) {
            setError({ contact: "Please enter phone number" });
        }
        else if (!_.isEmpty(phone) && !isValidContact(phone)) {
            setError({ contact: "Please enter valid phone number" });
        }
        else if (_.isEmpty(email) ||
            (!_.isEmpty(email) && !isValidEmail(email))
        ) {
            setError({ email: "Please enter valid email address" });
        }
        else if (_.isEmpty(file)) {
            setError({ file: "Please select CV" });
        }
        else {
            const formData = new FormData();
            formData.append("file", selectedFile);
            
            console.log(selectedFile, formData);
            axios.post('https://api.techautm.in/api/Master/save_candidate', {
                'Attachments': formData
            }, {
                params: {
                    "candidateId": 0,
                    "name": name,
                    "emailId": email,
                    "phone": phone,
                    "jobPost": Name.toString().slice(15).replace("%20", " "),
                    "appliedFrom": true,
                    "interviewAt": "",
                    "remark": "",
                    "status": true
                }
            })
                .then(function (response) {
                    if (response?.data?.responseCode === 0) {
                        hideModal();
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        }
    };

    return (
        <>
            <div id='modal' style={{ display: 'none' }}>
                <div className='form'>
                    <div style={{ alignItems: 'flex-end', color: "#ff1f1f", padding: '5px', cursor: 'pointer' }} onClick={hideModal} >
                        X
                    </div>
                    <div>
                        <input type="text" placeholder="Your Name" value={name} onChange={handleNameChange} />
                        <span className="errorMsg">{error?.name}</span>
                    </div>
                    <div>
                        <input placeholder="Enter your Phone Number" value={phone} onChange={handlePhoneChange} />
                        <span className="errorMsg">{error?.contact}</span>
                    </div>
                    <div>
                        <input type="mail" placeholder="Enter your Email" value={email} onChange={handleEmailChange} />
                        <span className="errorMsg">{error?.email}</span>
                    </div>
                    <div>
                        <input type="file" placeholder="Select your CV" value={file} onChange={handleFileChange} accept=".pdf" />
                        <span className="errorMsg">{error?.file}</span>
                    </div>
                    <button className="career-card-button" onClick={handleSubmit}>Send Mesage</button>
                </div>
            </div>
            <BrowserView>
                <section className="bg-section1 h-40 start-center">
                    <p className="tag mx-100px">Apply For</p>
                    <h1 className="page-title">{Name.toString().slice(15).replace("%20", " ")}</h1>
                </section>
                <section className="mx-100px py-100px">
                    <h2 className="heading m-0 mb-50px">Job Description</h2>
                    <div className="flex row">
                        <div className="flex col7">
                            <div>
                                <h3 className="ListName">Key Responsibility</h3>
                                <JobList head={"Responsibility"} />
                            </div>
                            <div className="mt-40px">
                                <h3 className="ListName">Knowledge And Skills</h3>
                                <JobList head={"Skills"} />
                            </div>
                        </div>
                        <div className="flex col3 career-card">
                            Requirements
                            <div className="career-card-title">Work Experience</div>
                            <div className="career-card-data"><JobList head={"Experience"} /></div>
                            <div className="career-card-title">Job Type</div>
                            <div className="career-card-data"><JobList head={"jobType"} /></div>
                            <div className="career-card-title">Posted On</div>
                            <div className="career-card-data"><JobList head={"posted"} /></div>
                            <button className='career-card-button' onClick={showModal}>Upload Your CV</button>
                        </div>
                    </div>
                </section>
            </BrowserView>
            <MobileView>
                <section className="bg-section1 h-50 start-center">
                    <p className="tag mx-100px">Apply For</p>
                    <h1 className="page-title">{Name.toString().slice(15).replace("%20", " ")}</h1>
                </section>
                <section className="mx-20px py-30px">
                    <div className="flex col mb-30px career-card">
                        Requirements
                        <div className="career-card-title">Work Experience</div>
                        <div className="career-card-data"><JobList head={"Experience"} /></div>
                        <div className="career-card-title">Job Type</div>
                        <div className="career-card-data"><JobList head={"jobType"} /></div>
                        <div className="career-card-title">Posted On</div>
                        <div className="career-card-data"><JobList head={"posted"} /></div>
                        <button className='career-card-button'>Upload Your CV</button>
                    </div>
                    <h2 className="heading m-0 mb-20px">Job Description</h2>
                    <div className="flex row">
                        <div className="flex col">
                            <div>
                                <h3 className="ListName">Key Responsibility</h3>
                                <JobList head={"Responsibility"} />
                            </div>
                            <div className="mt-40px">
                                <h3 className="ListName">Knowledge And Skills</h3>
                                <JobList head={"Skills"} />
                            </div>
                        </div>
                    </div>
                </section>
            </MobileView>
        </>
    );
}

export default CareerDesc;