import React from 'react';

const NoPage = () => {

    return (
        <div className="h-100 bg-section1 P404 center-center">
            <svg version="1.1" id="Layer_1" viewBox="0 0 496 496" height={"150px"}>
            <path stroke="url(#paint_linear)" fill="url(#paint_linear)" d="M496,476.8c0,10.4-8.8,19.2-19.2,19.2H19.2C8.8,496,0,487.2,0,476.8V19.2C0,8.8,8.8,0,19.2,0h457.6
                C487.2,0,496,8.8,496,19.2V476.8z"/>
            <path fill="#FDFFFF" d="M16,80v381.6C16,472,23.2,480,32.8,480h430.4c9.6,0,16.8-8,16.8-18.4V80H16z"/>
            <path fill="#E8EFEF" d="M463.2,480c9.6,0,16.8-8,16.8-18.4V80H16"/>
            <rect x="16" y="80" fill="#DCE5E5" width="464" height="24"/>
            <path fill="#FDFFFF" d="M395.2,40c0,9.6-8,16-17.6,16H32c-9.6,0-8-6.4-8-16l0,0c0-9.6-1.6-16,8-16h345.6
                C387.2,24,395.2,30.4,395.2,40L395.2,40z"/>
            <path fill="#E6EFEF" d="M24,40.8L24,40.8c0-9.6-1.6-16.8,8-16.8h345.6c9.6,0,17.6,7.2,17.6,16.8l0,0"/>
            <circle opacity="0.4" fill="#B1BCBC" enable-background="new" cx="426.4" cy="41.6" r="8.8"/>
            <circle fill="#fff" cx="426.4" cy="37.6" r="8.8"/>
            <path fill="#E8EFEE" d="M420,31.2c3.2-3.2,9.6-3.2,12.8,0c3.2,3.2,3.2,9.6,0,12.8"/>
            <circle opacity="0.4" fill="#B1BCBC" enable-background="new" cx="462.4" cy="41.6" r="8.8"/>
            <circle fill="#fff" cx="462.4" cy="37.6" r="8.8"/>
            <path fill="#E8EFEE" d="M456,31.2c3.2-3.2,9.6-3.2,12.8,0c3.2,3.2,3.2,9.6,0,12.8"/>
            <path fill="#8EA5A4" d="M100.8,32l-0.8-0.8h-2.4c-0.8,0-0.8,0-0.8,0.8l-1.6,6.4l0,0l0,0L92.8,32c-0.8,0-0.8,0-1.6,0h-2.4
                C88,32,88,32,88,32.8L85.6,40l0,0l0,0L84,33.6c0-1.6,0-1.6-0.8-1.6h-2.4H80h-0.8h-2.4C76,32,76,32,76,32.8l-1.6,6.4l0,0l0,0L72,32.8
                c0-0.8-0.8-0.8-1.6-0.8h-1.6c-0.8,0-0.8,0-1.6,0.8l-1.6,6.4l0,0l0,0l-2.4-6.4c0-0.8,0-0.8-0.8-0.8H60h-0.8c0,0,0,0-0.8,0H56
                c0,0-0.8,0-0.8,0.8l-1.6,6.4l0,0l0,0l-2.4-6.4c0-0.8-0.8-0.8-1.6-0.8H48c-0.8,0-0.8,0-0.8,0.8l-2.4,6.4l0,0l0,0l-1.6-6.4
                C42.4,32,42.4,32,41.6,32h-2.4c0,0-0.8,0-0.8,0.8v0.8l4,13.6c0,0.8,0.8,0.8,0.8,0.8h2.4c0.8,0,0.8,0,0.8-0.8l2.4-7.2c0,0,0,0,0-0.8
                c0,0,0,0,0,0.8l2.4,6.4c0,0.8,0.8,0.8,0.8,0.8h2.4c0.8,0,0.8,0,0.8-0.8l4-12l3.2,12c0,0.8,0.8,0.8,0.8,0.8h2.4c0.8,0,0.8,0,0.8-0.8
                l3.2-6.4c0,0,0,0,0-0.8c0,0,0,0,0,0.8l2.4,6.4c0,0.8,0.8,0.8,0.8,0.8h2.4c0.8,0,0.8,0,0.8-0.8l4-12l3.2,12c0,0.8,0.8,0.8,0.8,0.8
                h2.4c0.8,0,0.8,0,0.8-0.8l3.2-6.4c0,0,0,0,0-0.8c0,0,0,0,0,0.8l2.4,6.4c0,0.8,0.8,0.8,0.8,0.8H96c0.8,0,0.8,0,0.8-0.8l4-13.6
                C101.6,32.8,101.6,32,100.8,32z"/>
            <g>
                <circle fill="#DCE5E5" cx="111.2" cy="248" r="23.2"/>
                <circle fill="#DCE5E5" cx="384.8" cy="248" r="23.2"/>
                <path fill="#DCE5E5" d="M304.8,360c-6.4,0-12-5.6-12-12c0-24.8-20-44.8-44.8-44.8s-44.8,20-44.8,44.8c0,6.4-5.6,12-12,12
                    s-12-5.6-12-12c0-38.4,31.2-68.8,68.8-68.8s68.8,31.2,68.8,68.8C316.8,354.4,312,360,304.8,360z"/>
            </g>
            <g>
                <circle fill="url(#paint_linear)" cx="111.2" cy="236" r="23.2"/>
                <circle fill="url(#paint_linear)" cx="384.8" cy="236" r="23.2"/>
                <path fill="url(#paint_linear)" d="M304.8,348c-6.4,0-12-5.6-12-12c0-24.8-20-44.8-44.8-44.8s-44.8,20-44.8,44.8c0,6.4-5.6,12-12,12
                    s-12-5.6-12-12c0-38.4,31.2-68.8,68.8-68.8s68.8,31.2,68.8,68.8C316.8,342.4,312,348,304.8,348z"/>
            </g>
            <defs>
                                                <linearGradient id="paint_linear" x1="0%" y1="0" x2="100%" y2="0" gradientUnits="userSpaceOnUse">
                                                <stop offset="0%" stopColor="#333399"/>
                                                <stop offset="100%" stopColor="#FF00CC"/>
                                                </linearGradient>
                                            </defs>
            </svg>
            <h1>404</h1>
            <button type="button" onClick={() => window.location.href='/' }>Back to Home</button>
        </div>
    );
  };
  
  export default NoPage;