const amita = require('../Image/Amita Shah.jpg');
const amitashah = require('../Image/Amita Shah.png');
const sameer = require("../Image/Sameer Savani.jpg");

export const data = [
    {
        sr: 0,
        img: amita,
        testimonial: "It was a very good experience to work with TECHAUTM SOLUTION. They delivered the project before the estimated dead line with good quality and high commitment I will add them to my favorite freelancer list.  Thanks team for your support and great work",
        ceoName: "Amita Shah",
        ceoPost: "Proprietor of Shetrapal Corporation",
    },
    {
        sr: 1,
        img: amitashah,
        testimonial: "For a sample work on Fiverr I came across one ui/ux designer of your company.I was in urgent need of some sample work ..and it was the weekend..this guy was so helpful and his dedication even for sample work was like he was being paid for it... Now I know the reason behind the success of your company's dedication.",
        ceoName: "As",
        ceoPost: "CEO of Corporation",
    },
    {
        sr: 2,
        img: sameer,
        testimonial: "These guys are the absolute best at what they do. They give and give and will go to every end to ensure perfection and excellence. They are a top-notch team.",
        ceoName: "Sameer Savani",
        ceoPost: "Proprietor of Savani Snehal",
    },
];

export const menuIcon = <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M3 6.25H21M3 12H21M3 17.75H21" stroke="white" strokeWidth="1.4" strokeLinecap="round"/>
</svg>;

export const jobDetails = [
    {
        jobName: 'Back-End Developer',
        Experience: '1-2 Years',
        jobType: 'Full Time',
        posted: '05 Jun 2024',
        Responsibility: [
            "Write code and tests, build prototypes, resolve issues, and profile and analyze bottlenecks",
            "Design robust APIs to support mobile and desktop clients",
            "Manage and optimize scalable distributed systems in the cloud",
            "Optimize web applications for performance and scalability",
            "Develop automated tests to ensure business needs are met, and allow for regression testing"
        ],
        Skills: [
            "Excellent foundation in computer science, algorithms, and web design",
            "Experience in writing highly secure web applications",
            "Experience with core AWS web-enabling technologies",
            "Growth mindset that challenges the status quo and focuses on outside-the-box ideas and solutions",
            "Person should have knowledge of Dot Net Core, C# and SQL Server"
        ],
    },
    {
        jobName: 'React Developer',
        Experience: '6 Months - 1 Year',
        jobType: 'Full Time',
        posted: '05 Jun 2024',
        Responsibility: [
            "Developing new user-facing features using React.js",
            "Building reusable components and front-end libraries for future use",
            "Translating designs and wireframes into high quality code",
            "Optimizing components for maximum performance across a vast array of web-capable devices and browsers"
        ],
        Skills: [
            "Strong proficiency in JavaScript, including DOM manipulation and the JavaScript object model",
            "Thorough understanding of React.js and its core principles",
            "Experience with popular React.js workflows such as Redux",
            "Familiarity with RESTful APIs",
            "Knowledge of modern authorization mechanisms, such as JSON Web Token",
            "Familiarity with modern front-end build pipelines and tools",
            "Experience with common front-end development tools such as Babel, Webpack, NPM, etc",
            "Ability to understand business requirements and translate them into technical requirements",
            "Familiarity with code versioning tools such as Git",
        ],
    },
    {
        jobName: 'Accountant',
        Experience: '3-4 Years',
        jobType: 'Full Time',
        posted: '05 Jun 2024',
        Responsibility: [
            "Manage all accounting transactions",
            "Prepare budget forecasts",
            "Publish financial statements in time",
            "Handle monthly, quarterly and annual closings",
            "Reconcile accounts payable and receivable",
            "Ensure timely bank payments",
            "Compute taxes and prepare tax returns",
            "Manage balance sheets and profit/loss statements",
            "Report on the company's financial health and liquidity",
            "Audit financial transactions and documents",
            "Reinforce financial data confidentiality and conduct database backups when necessary",
            "Comply with financial policies and regulations",
        ],
        Skills: [
            "Excellent knowledge of accounting regulations and procedures, including the Generally Accepted Accounting Principles",
            "Hands-on experience with accounting software like Tally",
            "Advanced MS Excel skills including Vlookups and pivot tables",
            "Experience with general ledger functions",
            "Strong attention to detail and good analytical skills",
            "BSc in Accounting, Finance or relevant degree",
            "Additional certification (CPA or CMA) is a plus",
        ],
    },
];

export const portfolio = [
    {
        PortfolioId: 1,
        Heading: "Valani Logistic's Informative website",
        Img: 'https://valanilogistic.com/',
        Describtion: [
            "Write code and tests, build prototypes, resolve issues, and profile and analyze bottlenecks",
            "Design robust APIs to support mobile and desktop clients",
            "Manage and optimize scalable distributed systems in the cloud",
            "Optimize web applications for performance and scalability",
            "Develop automated tests to ensure business needs are met, and allow for regression testing"
        ],
    },
];
