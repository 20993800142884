import React, { useState } from 'react';
import { BrowserView, MobileView } from 'react-device-detect';
import _ from "lodash";
import axios from 'axios';
//import address from "../Image/Contact/address.png";
import loader from "../Image/loading.gif";
import call from "../Image/Contact/call.png";
import mail from "../Image/Contact/mail.png";

function ContactUs() {
    const [error, setError] = useState({
        name: "",
        email: "",
        contact: "",
        subject: "",
        message: ""
    });
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');

    function isValidEmail(email) {
        // eslint-disable-next-line
        return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email);
    }

    function isValidContact(phone) {
        // eslint-disable-next-line
        return /^(\+91[\-\s]?)?[0]?(91)?[789]\d{9}$/.test(phone);
    }

    function show() {
        document.getElementById('loader').classList.remove('none');
        document.getElementById('heading').classList.add('none');
        document.getElementById('form').classList.add('none');
    }

    function hide() {
        document.getElementById('loader').classList.add('none');
        document.getElementById('heading').classList.remove('none');
        document.getElementById('form').classList.remove('none');
    }

    const handleNameChange = (e) => {
        setName(e.target.value);
        setError({ name: "" });
    };
    const handleSubjectChange = (e) => {
        setSubject(e.target.value);
        setError({ subject: "" });
    };
    const handlePhoneChange = (e) => {
        if (e?.nativeEvent?.data === '+' && phone === '') {
            setPhone(e.target.value);
            setError({ contact: "" });
        }
        else if (isNaN(e?.nativeEvent?.data)) {
            return;
        }
        else {
            setPhone(e.target.value);
            setError({ contact: "" });
        }
    };
    const handleEmailChange = (e) => {
        setEmail(e.target.value);
        setError({ email: "" });
    };
    const handleMessageChange = (e) => {
        setMessage(e.target.value);
        setError({ message: "" });
    };

    const handleSubmit = () => {
        if (_.isEmpty(name)) {
            setError({ name: "Please enter name here" });
        }
        else if (_.isEmpty(phone)) {
            setError({ contact: "Please enter phone number" });
        }
        else if (!_.isEmpty(phone) && !isValidContact(phone)) {
            setError({ contact: "Please enter valid phone number" });
        }
        else if (_.isEmpty(email) ||
            (!_.isEmpty(email) && !isValidEmail(email))
        ) {
            setError({ email: "Please enter valid email address" });
        }
        else if (_.isEmpty(subject)) {
            setError({ subject: "Please enter subject" });
        }
        else if (_.isEmpty(message)) {
            setError({ message: "Tell us what you want to say" });
        }
        else {
            show();
            axios.post('https://api.techautm.in/api/Master/save_visitorlead', {
                "visitorLeadId": 0,
                "name": name,
                "emailId": email,
                "mobileNo1": phone,
                "mobileNo2": "",
                "subject": subject,
                "message": message,
                "status": true
            })
                .then(function (response) {
                    hide();
                    if (response?.data?.responseCode === '0') {
                        document.getElementById('form').innerHTML = "<div class='success'> We got the info, Our team will contact you soon. </div>";
                    }
                    else {
                        document.getElementById('form').innerHTML = "<div class='warning'> Sorry for inconvenice but something went wrong. You can try latter or call us directly. </div>";
                    }
                })
                .catch(function (error) {
                    hide();
                    alert(error);
                });
        }
    };

    return (
        <>
            <BrowserView>
                <section className="bg-section1 h-50 start-center">
                    <p className="tag mx-100px">Contact Us</p>
                    <h1 className="page-title">Get in touch</h1>
                    <h6 className="page-desc">Get in touch with us and work togather.</h6>
                </section>
                <section className="mx-100px py-100px">
                    <h2 className="heading m-0">Contact Us</h2>
                    <div className="flex row">
                        <div className="flex col5 gap-32px">
                            {/* <div className="flex row align-items-center">
                                <img className="contactIcon" src={address} alt='address icon' />
                                <div>
                                    <h3 className="contactName">Address</h3>
                                    <p className="contactData"></p>
                                </div>
                            </div> */}
                            <div className="flex row align-items-center">
                                <img className="contactIcon" src={call} alt='call icon' />
                                <div>
                                    <h3 className="contactName">Call Us On</h3>
                                    <a className="contactData" href="tel:+91 9106533086">+91 91065 33086</a>
                                    <a className="contactData" href="tel:+91 9664518737">+91 96645 18737</a>
                                </div>
                            </div>
                            <div className="flex row align-items-center">
                                <img className="contactIcon" src={mail} alt='call icon' />
                                <div>
                                    <h3 className="contactName">Mail Us on</h3>
                                    <a className="contactData" href="mailto:info@techautm.in">info@techautm.in</a>
                                </div>
                            </div>
                        </div>
                        <div className="flex col5">
                            <div className="career-card br-16px px-30px">
                                <img id='loader' alt='Loader' className='none' src={loader} width={'100%'} />
                                <h3 id='heading' className="card-title">Let's talk</h3>
                                <div id='form'>
                                    <div>
                                        <input type="text" placeholder="Your Name" value={name} onChange={handleNameChange} />
                                        <span className="errorMsg">{error?.name}</span>
                                    </div>
                                    <div>
                                        <input placeholder="Enter your Phone Number" value={phone} onChange={handlePhoneChange} />
                                        <span className="errorMsg">{error?.contact}</span>
                                    </div>
                                    <div>
                                        <input type="mail" placeholder="Enter your Email" value={email} onChange={handleEmailChange} />
                                        <span className="errorMsg">{error?.email}</span>
                                    </div>
                                    <div>
                                        <input type="text" placeholder="Subject" value={subject} onChange={handleSubjectChange} />
                                        <span className="errorMsg">{error?.subject}</span>
                                    </div>
                                    <div>
                                        <textarea placeholder="Enter your message" value={message} onChange={handleMessageChange} />
                                        <span className="errorMsg">{error?.message}</span>
                                    </div>
                                    <button className="career-card-button" onClick={handleSubmit}>Send Mesage</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </BrowserView>
            <MobileView>
                <section className="bg-section1 h-50 start-center">
                    <p className="tag mx-100px">Contact Us</p>
                    <h1 className="page-title">Get in touch</h1>
                    <h6 className="page-desc">Get in touch with us and work togather.</h6>
                </section>
                <section className="mx-20px py-30px">
                    <h2 className="heading m-0">Contact Us</h2>
                    <div className="flex col gap-16px">
                        {/* <div className="flex row align-items-center">
                                <img className="contactIcon" src={address} alt='address icon' />
                                <div>
                                    <h3 className="contactName">Address</h3>
                                    <p className="contactData"></p>
                                </div>
                            </div> */}
                        <div className="flex row align-items-center">
                            <img className="contactIcon" src={call} alt='call icon' />
                            <div>
                                <h3 className="contactName">Call Us On</h3>
                                <a className="contactData" href="tel:+91 9106533086">+91 9106533086</a>
                                <a className="contactData" href="tel:+91 9664518737">+91 96645 18737</a>
                            </div>
                        </div>
                        <div className="flex row align-items-center">
                            <img className="contactIcon" src={mail} alt='call icon' />
                            <div>
                                <h3 className="contactName">Mail Us on</h3>
                                <a className="contactData" href="mailto:info@techautm.in">info@techautm.in</a>
                            </div>
                        </div>
                    </div>
                    <div className="flex col">
                        <div className="career-card br-16px mt-40px">
                            <img id='loader' alt='Loader' className='none' src={loader} width={'100%'} />
                            <h3 id='heading' className="card-title">Let's talk</h3>
                            <div id='form'>
                                <div>
                                    <input type="text" placeholder="Your Name" value={name} onChange={handleNameChange} />
                                    <span className="errorMsg">{error?.name}</span>
                                </div>
                                <div>
                                    <input placeholder="Enter your Phone Number" value={phone} onChange={handlePhoneChange} />
                                    <span className="errorMsg">{error?.contact}</span>
                                </div>
                                <div>
                                    <input type="mail" placeholder="Enter your Email" value={email} onChange={handleEmailChange} />
                                    <span className="errorMsg">{error?.email}</span>
                                </div>
                                <div>
                                    <input type="text" placeholder="Subject" value={subject} onChange={handleSubjectChange} />
                                    <span className="errorMsg">{error?.subject}</span>
                                </div>
                                <div>
                                    <textarea placeholder="Enter your message" value={message} onChange={handleMessageChange} />
                                    <span className="errorMsg">{error?.message}</span>
                                </div>
                                <button className="career-card-button" onClick={handleSubmit}>Send Mesage</button>
                            </div>
                        </div>
                    </div>
                </section>
            </MobileView>
        </>
    );
}

export default ContactUs;